.home-section-2 {
  background-image: url(./assets/images/home/fundo_banner.png) !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 1660px) {
  .header-2 .right-nav .nav-number {
    display: block !important;
  }
}

@media screen and (max-width: 1366px) {
  .header-2 .right-nav {
    display: block !important;
  }

  .header-2 .right-nav .nav-number {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .header-2 .right-nav {
    display: none !important;
  }

  .header-2 .right-nav .nav-number {
    display: none !important;
  }

  .shopping-cart-icon i {
    padding-top: 12% !important;
  }
}

@media screen and (max-width: 1604px) {
  .header-2 .top-nav .middle-box .searchbar-box input {
    width: 690px !important;
  }
}

@media screen and (min-width: 1340px) and (max-width: 1440px) {
  .header-2 .top-nav .middle-box .searchbar-box input {
    width: 650px !important;
  }

  .header-2 .rightside-menu .option-list > ul > li:nth-of-type(5n-3) {
    margin: 0px 0px 0px 22px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1340px) {
  .header-2 .top-nav .middle-box .searchbar-box input {
    width: 540px !important;
  }

  .header-2 .rightside-menu .option-list > ul > li:nth-of-type(5n-3) {
    margin: 0px 0px 0px 22px !important;
  }
}

@media screen and (max-width: 1199px) {
  .header-2 .rightside-menu .option-list > ul > li:nth-of-type(5n-3) {
    margin: 0px 0px 0px 22px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1460px) {
  .header-2 .rightside-menu .option-list > ul > li:nth-of-type(5n-3) {
    margin: 0px 10px 0px 0px !important;
  }
}

@media screen and (min-width: 1200px) {
  .header-2 .rightside-menu .option-list > ul > li:nth-of-type(5n-3) {
    display: block !important;
    margin: 0px 10px;
  }
}

.flex-container {
  display: flex !important;
  flex-direction: row !important;
  margin: -7.5px !important;
  flex-wrap: wrap !important;
}

.flex-item {
  flex: 1 1 100% !important;
}

.classic-table {
  table,
  th,
  td {
    border: 1px solid black;
    padding: 10px;
  }
}

.category-section-2 .category-slider .shop-category-box a.circle-1::before {
  background-color: #aae3dcc2 !important;
}

.counter-number {
  margin-top: 15px;
}

.text-product {
  overflow-y: scroll;
  height: 100%;
  max-height: 150px;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

::-webkit-scrollbar {
  width: 6px;
  background: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background: #dad7d7;
}

.message-limit-product {
  margin-top: 10px;
  background-color: #fbeec1;
  width: 180px;
  height: 35px;
  border-radius: 0.25rem;

  div > label {
    padding: 8px;
    padding-left: 15px;
  }
}

.div-disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}

.bold-title {
  font-weight: bold;
}

.box-payment-cards {
  background-color: #f9f9f9;
  padding: 7px;
}

.product-detail-page {
  .product-box-3 {
    min-height: 415px !important;
  }
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li {
  white-space: pre-wrap !important;
}

.image-view-car {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover !important;
}

.cursor-pointer-button {
  cursor: pointer;
}

.price-qty-custom {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: space-between !important;
  margin-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320))) !important;
}

.counter-number-custom {
  display: inline-block !important;
}

.buy-button-custom {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 6px 15px !important;
  background-color: var(--theme-color) !important;
  width: 53px !important;
  margin-right: 10px !important;
}

.iconly-Buy-custom {
  font-size: 24px !important;
}

.checkout-icon-delivery {
  padding: 14px 10px !important;
}

.checkout-icon-payment {
  padding: 12px 18px !important;
}

.icon-custom {
  font-size: 24px;
}

.checkout-section-2
  .left-sidebar-checkout
  .checkout-detail-box
  > ul
  > li
  .checkout-box
  .checkout-detail
  .custom-accordion
  .accordion-item
  .accordion-header
  .accordion-button-custom::before {
  display: none;
}

.text-custom {
  font-weight: bold;
}

.icon-custom-alert {
  float: right;
  margin-right: 6px;
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}

.category-dropdown {
  overflow-y: scroll;
  max-height: 480px;
}

header .onhover-dropdown .onhover-div .button-group .cart-button {
  padding: 6px 6px !important;
}

.form-input-password {
  display: inline-block !important;
}

.icon-button-password {
  margin-left: -30px;
  position: absolute;
  display: inline-block !important;
  top: 20px;
}

.button-icon-remove {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  right: 13px;
  color: #777;
  cursor: pointer;
}

.custom-accordion-shop {
  display: block !important;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .custom-service-box {
    margin-top: 20px;
    flex: 30 30 auto !important;
  }

  .shopping-cart-icon i {
    padding-top: 12% !important;
  }
}

.onhover-cart-custom {
  overflow-y: scroll;
  max-height: 480px;
}

.title-custom-filters {
  font-weight: bold;
}

.title-label-product {
  max-width: 240px;
}

.image-qr-code {
  margin: 0 auto !important;
  display: block !important;
}

.label-app-qr-code {
  padding: 5px;
  background-color: var(--theme-color) !important;
  max-width: 115px;
  border-radius: 4px;
  margin: 0 auto;
  display: grid;

  label {
    font-size: 13px;
    text-align: center;
    color: #ffff;
  }
}

.title-address-account {
  margin-top: 28px;
  display: block;
}

.space-box {
  margin-bottom: 20px;
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile-custom {
  border-bottom: none !important;
}

.title-account-box,
.edit-buttom-account {
  display: inline-block;
}

.edit-buttom-account {
  float: right;
}

.button-custom-add-contacts {
  border: none;
  background: #fff;
  cursor: pointer;
  color: var(--theme-color);
  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-family: "Public Sans", sans-serif;
}

.table-order {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.row-table-order {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  background: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  td {
    padding: 10px;
    text-align: center;
  }
}

.btn-details-products {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.visibility-div {
  content-visibility: visible !important;
}

.hidden-div {
  content-visibility: hidden !important;
}

.address-detail-responsive {
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border: 1px solid #d5d5d5;
}

